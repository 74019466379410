
  import React, { Fragment } from "react";
  import { Link } from 'react-router-dom';
  import Card from 'react-bootstrap/Card';
  import Col from 'react-bootstrap/Col';
  import Container from 'react-bootstrap/Container';
  import Row from 'react-bootstrap/Row';
  import Background from "../../assets/image/bakground-image.png";
  import CancelIcon from '../../assets/icons/cancel-icon.svg'
  
  const NotFound = () => {
      const backgroundStyle = {
          backgroundImage: `url(${Background})`,
          objectFit: "fill",
          width: "100%",
      };
  
      return (
          <Fragment>
              <img
                  src={Background}
                  style={backgroundStyle}
                  loading="lazy"
                  alt="Nectar banner background"
              />
              <div className="container text-center form-center">
                  <Container>
                      <Row>
                          <Col></Col>
                          <Col xs={4}>                
                          <Card className="shadow-lg p-3 mb-5 bg-white">
                              {/* <Card.Header>Featured</Card.Header> */}
                              <Card.Body>
                                  {/* Featured */}
                                  <img className="cancel-image" src={CancelIcon} alt="Cancel Icon" />
                                  <Card.Title className="mt-4">Wooops!</Card.Title>
                                  <Card.Text className="mt-2 mb-4">
                                     Page not found 404.
                                  </Card.Text>
                                  <Link to="/" className="shadow rounded try-btn">GO HOME</Link>
                              </Card.Body>
                          </Card></Col>
                          <Col></Col>
                      </Row>
                  </Container>
  
  
  
  
              </div>
          </Fragment>
      );
  };
  
  export default NotFound;
  