import React, { useEffect, useState, useContext } from 'react';
import Image from 'react-bootstrap/Image'
import { OrderContext } from '../../context/OrderContext';
import defaultUrl from '../../assets/image/rolling-spinner.gif';

const ProductImage = ({ id, imageAlt }) => {
    const [imageUrl, setImageUrl] = useState('');
    const { fetchProductImage } = useContext(OrderContext);

    useEffect(() => {        
        fetchProductImage(id).then((imgUrl) => {
            setImageUrl(imgUrl)
        });
    }, [fetchProductImage, id])

    return (
        <Image
            src={imageUrl || defaultUrl}
            loading="lazy"
            width="100" height="100"
            alt={imageAlt}
            rounded
            className="mt-4"
        />
    )
}

export default ProductImage
