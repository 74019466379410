import axios from 'axios';
import httpHeaderInterceptor from "../interceptors/httpHeaders";
import jwtErrorInterceptor from "../interceptors/jwtErrorInterceptor";

const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URI,
  withCredentials: false
});

http.interceptors.request.use(httpHeaderInterceptor,(error) => {
  return Promise.reject(error);
});

http.interceptors.response.use(response => response, (error) => jwtErrorInterceptor(error, http));

export default http;
