import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { OrderProvider } from './context/OrderContext';
import { AuthContext } from './context/AuthContext';

import SignIn from './pages/SignIn/SignIn';
import ProtectedRoute from './utils/ProtectedRoute';
import DashboardHolder from './pages/Dashboard/DashboardHolder';
import SingleOrder from './pages/SingleOrder/SingleOrder'
import NotFound from './pages/NotFound/NotFound';
import ErrorPage from './pages/ErrorPage/ErrorPage';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

toast.configure()

const App = () => {

  const [loggedIn, setLoggedIn] = useState(localStorage.getItem("token") ? true : false);
  return (
    <Router>
      <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
        <OrderProvider>
          <Switch>
            <Route exact path="/" component={SignIn} />
            <Route path="/nectardemo" component={SignIn} />
            <Route path="/signin" render={props => <SignIn {...props} />} />

            <ProtectedRoute exact path="/dashboard" component={DashboardHolder} />
            <Route path="/single-order" render={props => <SingleOrder {...props} title="Edit Order"/>} />
            <Route path="/error" component={ErrorPage} />
            <Route path="*" component={NotFound} />
          </Switch>
        </OrderProvider>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
