import React, { Fragment, useContext } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Media from 'react-bootstrap/Media';
import Col from 'react-bootstrap/Col';

import DashboardSideBar from '../../components/DashboardSideBar/DashboardSideBar';
import { OrderContext } from '../../context/OrderContext';
import ProductImage from '../../components/ProductImage/ProductImage';
import './SingleOrder.css';

const SingleOrder = ({ title, ...props }) => {

    const { singlePage, updateSingleOrderStatus } = useContext(OrderContext);
    const data = [singlePage]

    const formatDateToTime = (date) => {
        var event = new Date(date);
        return event.toLocaleTimeString('en-US')
    }

    const formatDate = (date) => {
        const formattedDate = new Date(date).toDateString()
        // The above yields e.g. 'Mon Jan 06 2020'
        const [, month, day, year] = formattedDate.split(' ')
        return `${month} ${day}, ${year}`
    }

    const getDeliveryDate = (itemData) => {
        const data = itemData.meta_data.filter(item => item.key === 'Delivery Date');
        return data[0]?.value;
    }

    const getDeliveryOption = (itemData) => {
        const data = itemData.meta_data.filter(item => item.key === 'daypart');
        return data[0]?.value;
    }

    const getShippingPhone = (itemData) => {
        const data = itemData.meta_data.filter(item => item.key === '_shipping_phone');
        return data[0]?.value;
    }

    const displayCouponCode = (couponArray) => {
        if (couponArray === undefined || couponArray.length === 0) {
            return '-'
        }
        else {
            return couponArray[0]['code']
        }
    }

    const orderNotes = (htmlString) => {

        let orderNote = htmlString.meta_data.filter(item => item.key === "_ORDER NOTES:")
        // order notes is empty
        if (!orderNote || orderNote.length === 0) {
            // array empty or does not exist
        } else {
            return `<strong>ORDER NOTES:&nbsp; </strong>${orderNote[0].value}`;
        }
    }

    const cakeFlavours = (htmlString) => {

        let cakeFlavour = htmlString.meta_data.filter(item => item.key === "_CAKE FLAVOUR (Kindly choose flavour by number of layers in Cake):");

        if (!cakeFlavour || cakeFlavour.length === 0) {

        } else {
            return `<strong>CAKE FLAVOUR (Kindly choose flavour by number of layers in Cake):&nbsp; </strong> ${cakeFlavour[0].value}`;
        }
    }

    const cakeMessages = (htmlString) => {
        let cakeMessage = htmlString.meta_data.filter(item => item.key === "_CUSTOM CAKE MESSAGE (What would you have written on the cake) :");
        if (!cakeMessage || cakeMessage?.length === 0) {

        } else {
            return `<strong>CUSTOM CAKE MESSAGE (What would you have written on the cake):&nbsp; </strong> ${cakeMessage[0].value}`
        }

    }

    const cakeTopper = (htmlString) => {
        let checkTopper = htmlString.meta_data.filter(item => item.key === "_ADD TOPPER:");
        if (!checkTopper || checkTopper.length === 0) {
        } else {
            return `<strong>ADD TOPPER:&nbsp; </strong> ${checkTopper[0].value}`
        }
    }

    const cakeInscriptionImage = (htmlString) => {
       let cakeImage = htmlString.meta_data.filter(item => item.key === "_UPLOAD IMAGE (Want an image on the cake?):");
       if(!cakeImage || cakeImage.length === 0){
       }else{
        console.log('cake image found');
        console.log(cakeImage[0].value);
        return `<strong>UPLOAD IMAGE (Want an image on the cake?):&nbsp; </strong> ${cakeImage[0].value}`;
       }
       
    }

    let arryOfStatus = []

    const updateStatusOfOrder = (event, order) => {

        if (window.confirm(`Are you sure you want to update status of order ${order.id} to ${event.target.value} ?`)) {
            updateSingleOrderStatus(event.target.value, order)
        }
    }

    const single_order = data.map((item, index) => {
        // filter the current order status from the array
        arryOfStatus = arryOfStatus.filter(e => e !== item.status)
        console.log({item});
        if (Object.entries(item).length !== 0) {
            return (
                <Container key={index}>
                    <Row>
                        {/* LEFT */}
                        <Col xs={12} md={12} style={{ backgroundColor: "#FAFAFC", paddingLeft: "0", paddingRight: "0" }}>
                            <Col className="order-details">
                                <div style={{ padding: "1em 1em" }}>
                                    <h3>Order #{item.id} details</h3>
                                    <p>Payment via {item.payment_method_title}. Customer IP:
                                        {item.customer_ip_address}</p>
                                </div>
                            </Col>

                            <Col className="column-cards">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col className="general-column" style={{ marginRight: "2", paddingRight: "0", paddingLeft: "10" }}>
                                                <h5 className="headings"> General</h5>
                                                <Form style={{ paddingRight: "15" }}>
                                                    <h5 className="sub-headings"> Date Created: </h5>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Control placeholder={formatDate(item.date_created)} readOnly />
                                                        </Col>
                                                         @
                                                        <Col>
                                                            <Form.Control placeholder={formatDateToTime(item.date_created)} readOnly />
                                                        </Col>
                                                    </Form.Row>

                                                    <h5 className="sub-headings"> Status: </h5>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Control as="select"
                                                                id="selectedOrderStatus"
                                                                name="selectedOrderStatus"
                                                                onChange={(e) => updateStatusOfOrder(e, item)}
                                                            >
                                                                <option>{item.status} payment</option>
                                                                {arryOfStatus.map(function (name, index) {
                                                                    return <option key={name} value={name}>{name}</option>;
                                                                })}
                                                            </Form.Control>
                                                        </Col>
                                                    </Form.Row>

                                                    <h5 className="sub-headings"> Customer: </h5>
                                                    <Form.Row>
                                                        <Col>
                                                            <Form.Control as="select" defaultValue="Choose...">
                                                                <option>Customer</option>
                                                            </Form.Control>
                                                        </Col>
                                                    </Form.Row>
                                                </Form>
                                            </Col>

                                            <Col className="middle-column" style={{ paddingLeft: "0", marginLeft: "1" }}>
                                                <h5>Billing</h5>
                                                <ul className="single-order-ul">
                                                    <li>
                                                        <p>{item.billing.first_name} {item.billing.last_name}</p>
                                                        <p>{item.address_1}</p>
                                                    </li>
                                                    <li>
                                                        <p><strong>Email Address:</strong></p>
                                                        <p> <a className="single-order-anchor" href={`mailto:${item.billing.email}`}>{item.billing.email}</a></p>
                                                    </li>
                                                    <li>
                                                        <p><strong>Phone:</strong></p>
                                                        <p> <a className="single-order-anchor" href={`tel:${item.billing.phone}`}>{item.billing.phone}</a></p>
                                                    </li>
                                                    <li>
                                                        <p><strong>Delivery Option:</strong></p>
                                                        <p>{getDeliveryOption(item)}</p>
                                                    </li>
                                                    <li>
                                                        <p><strong>Delivery date:</strong></p>
                                                        <p>{getDeliveryDate(item)}</p>
                                                    </li>
                                                </ul>
                                            </Col>

                                            <Col>
                                                <h5>Shipping</h5>
                                                <ul className="single-order-ul">
                                                    <li>
                                                        <p>{item.shipping.first_name} {item.shipping.last_name}</p>
                                                        <p>{item.shipping.address_1}</p>
                                                    </li>
                                                    <li>
                                                        <p><strong>Shipping Phone:</strong></p>
                                                        <p><a href={`tel:${item.shipping.phone}`} className="single-order-anchor">{item.shipping.phone}</a></p>
                                                    </li>
                                                </ul>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className="column-cards second-column-card">
                                <Card>
                                    <Card.Body style={{ padding: "0" }}>
                                        <div className="table-responsive">
                                            <Table striped borderless hover>
                                                <thead className="products-list">
                                                    <tr>
                                                        <th colSpan="4">Item</th>
                                                        <th>Cost</th>
                                                        <th>Qty</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        item.line_items.map((index, i) => (
                                                            <tr key={i}>
                                                                <td colSpan="4" className="line-items">
                                                                    <Container>
                                                                        {/* <Row>
                                                                            <Col xs={6} md={2}>
                                                                                <ProductImage key={index.product_id} id={index.product_id} imageAlt={index.name} />
                                                                            </Col>
                                                                            <Col xs={12} md={10}>
                                                                                <ul>
                                                                                    <li className="mt-2"><span className="product-name">{index.name}</span></li>
                                                                                    <li className="mt-2"><span></span><strong>SKU:</strong> {index.sku}</li>
                                                                                    <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: orderNotes(index) }} ></span></li>
                                                                                    <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: cakeFlavours(index) }} ></span></li>
                                                                                    <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: cakeMessages(index) }} ></span></li>
                                                                                    <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: cakeTopper(index) }} ></span></li>
                                                                                </ul>
                                                                            </Col>
                                                                        </Row> */}
                                                                        <ul className="list-unstyled">
                                                                            <Media as="li">
                                                                                <ProductImage key={index.product_id} id={index.product_id} imageAlt={index.name} />
                                                                                <Media.Body>
                                                                                    <ul>
                                                                                        <li className="mt-2"><span className="product-name">{index.name}</span></li>
                                                                                        <li className="mt-2"><span></span><strong>SKU:</strong> {index.sku}</li>
                                                                                        <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: orderNotes(index) }} ></span></li>
                                                                                        <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: cakeFlavours(index) }} ></span></li>
                                                                                        <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: cakeMessages(index) }} ></span></li>
                                                                                        <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: cakeTopper(index) }} ></span></li>
                                                                                        <li className="mt-2"><span dangerouslySetInnerHTML={{ __html: cakeInscriptionImage(index) }} ></span></li>
                                                                                        
                                                                                    </ul>
                                                                                </Media.Body>
                                                                            </Media>
                                                                        </ul>
                                                                    </Container>
                                                                </td>

                                                                <td className="line-items">
                                                                    <Container>
                                                                        <Row>
                                                                            <Col md={12}>
                                                                                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(index.price)}
                                                                            </Col>
                                                                        </Row>
                                                                    </Container>

                                                                </td>
                                                                <td className="line-items">
                                                                    x{index.quantity}
                                                                </td>
                                                                <td className="line-items">
                                                                    {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(index.total)}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                            <Col className="column-cards second-column-card">
                                <Card>
                                    <Card.Body style={{ padding: "0" }}>
                                        <div className="table-responsive">
                                            <Table striped borderless hover>
                                                <thead className="products-list">
                                                    <tr>
                                                        <th colSpan="4">
                                                            <p>{item.shipping_lines[0].method_title}</p>
                                                            <p className="text-muted">items:
                                                            {
                                                                    item.line_items.map((index, i) => (
                                                                        <span key={i} style={{ borderRight: "5 solid red" }}>{index.name} x{index.quantity} | </span>
                                                                    ))
                                                                }</p>
                                                        </th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th></th>
                                                        <th>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item.shipping_lines[0].total)}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="last-card-table">
                                                            Coupons(s)
                                                       </td>
                                                        <td></td>
                                                        <td colSpan="2"></td>
                                                        <td></td>
                                                        <td></td>
                                                        {/* <td></td> */}
                                                        <td colSpan="2">
                                                            <p>Items Subtotal:</p>
                                                            <p>Coupons(s)</p>
                                                            <p>Shipping:</p>
                                                            <p>Order total:</p>
                                                        </td>
                                                        <td colSpan="1">
                                                            <p>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format((item.total - item.shipping_total))}
                                                            </p>
                                                            <p> {displayCouponCode(item.coupon_lines)} </p>
                                                            <p>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item.shipping_total)}
                                                            </p>
                                                            <p>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item.total)}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Col>
                    </Row>
                </Container>
            )
        } else {
            return (<Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <DashboardSideBar />
                        <main role="main" className="col-md-12 ml-sm-auto col-lg-12 px-md-4">
                            <Col className="column-cards">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col className="text-center general-column" style={{ marginRight: "2", paddingRight: "0", paddingLeft: "10" }}>
                                                <h5 className="headings"> No Data found</h5>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </main>
                    </div>
                </div>
            </Fragment>)
        }
    })
    return (
        <Fragment>
            <div className="container-fluid">
                <div className="row">
                    <DashboardSideBar />
                    <main role="main" className="col-md-9 ml-sm-auto col-lg-10 px-md-4" style={{ marginLeft: '1em' }}>
                        <div>
                            <div>
                                <Breadcrumb className="breadcrumb">
                                    <Breadcrumb.Item className="orders-link" href="/dashboard" style={{ color: '#C6009 !important' }}>
                                        <span className="backto-dashboard"> Orders</span>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        <span className="edit-orders-link">Edit Order </span>
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <h1>{title}</h1>
                            </div>
                            {single_order}

                        </div>
                    </main>
                </div>
            </div>
        </Fragment>)

}

export default SingleOrder