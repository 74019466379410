import React, { Component } from 'react';
import { OrderContext } from '../../context/OrderContext';
import Dashboard from './Dashboard';

class DashboardHolder extends Component {
    static contextType = OrderContext;
    componentDidMount() {
        this.init();
    }

    init() {
        this.context.initialRequest();
    }

    render() {

        return <OrderContext.Consumer>
            {(context) => {
                const { orders, paginatedOrders } = context;
                return (<Dashboard orders={orders} paginatedOrders={paginatedOrders} />)
            }}
        </OrderContext.Consumer>
    }

};

export default DashboardHolder;