/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PaginationItems from "../PaginationItems/PaginationItems";
import { DatePicker } from 'antd';
import "antd/dist/antd.css";

const Navigator = (props) => {
  const { orders, paginate, searchFunctionality, resetDateState, setCurrentFilterType, setFilterDateValue, setPaginatedOrders } = props
  const [displayCancelBtn, setdisplayCancelBtn] = useState(false);

  // FORMAT DELIVERY DATE
  const formatDeliveryDateToISOString = (deliveryDate) => {
    if (deliveryDate) {
      var date = new Date(deliveryDate);
      var results = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
        .toISOString()
        .split("T")[0];
      return results
    }
  }

  const orderDateSearch = (orderDate) => {
    if (orderDate !== null) {
      setdisplayCancelBtn(true)
      let formattedOrderDate = formatDeliveryDateToISOString(orderDate._d)
      searchFunctionality(formattedOrderDate)
    }
  }

  // Reset date picker value
  const resetDatePicker = () => {
    resetDateState();
    setCurrentFilterType('')
    setFilterDateValue('')
    setdisplayCancelBtn(false)
  }
  return (
    <div className="row mt-3 mb-3">
      <div className="col-sm-6">
        <div className="row">
          <div className="col-sm-12">
            <DatePicker
              name="deliveryDate"
              id="deliveryDate"
              onChange={(e) => orderDateSearch(e)}
              placeholder="Search by delivery dates"
              allowClear={true}
            >
            </DatePicker>
            {
              (displayCancelBtn) ? (
                <button className="ml-2 text-muted date-clear-btn" onClick={resetDatePicker} >
                  Cancel
                </button>) : (<div></div>)
            }
          </div>
        </div>
      </div>
      <div className="col-sm-6">
        <div className="row float-right">
          <div className="">
            {/* <PaginationItems ordersHoc={orders} paginate={paginate} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Navigator;