import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Background from "../../assets/image/bakground-image.png";
import CancelIcon from '../../assets/icons/cancel-icon.svg';
import './ErrorPage.css';

const ErrorPage = () => {
    const backgroundStyle = {
        backgroundImage: `url(${Background})`,
        objectFit: "fill",
        width: "100%",
    };
    return (
        <Fragment>
            <img
                src={Background}
                style={backgroundStyle}
                loading="lazy"
                alt="Nectar banner background"
            />
            <div className="container text-center form-center">
                <Container>
                    <Row>
                        <Col></Col>
                        <Col xs={4}>
                            <Card className="shadow-lg p-3 mb-5 bg-white">
                                <Card.Body>
                                    {/* Featured */}
                                    <img className="cancel-image" src={CancelIcon} alt="Cancel Icon" />
                                    <Card.Title className="mt-4">Oh No!</Card.Title>
                                    <Card.Text className="mt-2 mb-4">
                                        Something went wrong.
                                </Card.Text>
                                    <Link to="/" className="shadow rounded try-btn" >TRY AGAIN</Link>
                                </Card.Body>
                            </Card></Col>
                        <Col></Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
};

export default ErrorPage;
