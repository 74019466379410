import axios from 'axios';
import cookie from "../utils/cookie";

const jwtErrorInterceptor = (error, http) => {
  const originalRequest = error.config;
  if (error.response && error.response.status === 403
    && !originalRequest._retry) {
      /* START */
      cookie.remove('refresh_token')
      cookie.remove('token')
      window.location.reload();
      /* END */
    originalRequest._retry = true;
    const rfToken = cookie.get('refresh_token');
    return http.get(`${process.env.REACT_APP_API_URL}/jwt-auth/v1/token/refresh?refresh_token=${rfToken}`)
      .then(res => {
        if (res.status === 200) {
          const {token, refresh_token} = res.data;
          if(token) {
            cookie.put('token', token);
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
          }
          if(refresh_token) {
            cookie.put('refresh_token', refresh_token);
          }
          return axios(originalRequest);
        }

      });
  }
  return Promise.reject(error);
};
export default jwtErrorInterceptor;
