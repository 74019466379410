import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { OrderContext } from '../../context/OrderContext';

class DashboardHoc extends Component {

    static contextType = OrderContext

    // eslint-disable-next-line
    constructor(props) {
        super(props);
        this.handleScroll = this.handleScroll.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);
    }

    state = {
        ordersone: [],
        requested: false,
        search: '',
        searchByStatus: false,
        height: window.innerHeight
    }

    handleScroll() {
        const { updateRequest, currentPage, setpaginationBtnLoading, isLoadingMore } = this.context
        const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
        const body = document.body;
        const html = document.documentElement;
        const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        const windowBottom = windowHeight + window.pageYOffset;

        if (windowBottom + 1 >= docHeight) {

            // console.log('At the bottom')
            // console.log({ windowBottom, docHeight })
            // console.log('isLoadingMore is ', isLoadingMore)
            setpaginationBtnLoading(true)
            let nextPage = currentPage + 1;
            if (isLoadingMore === false) {
                updateRequest(nextPage)
            }
        } else {
            console.log('the_default_value')
            console.log({ windowBottom, docHeight })
        }
    }

    stickyHeader() {
        // Stcky header
        // Get the header
        let dashboardHeader = document.getElementById("dashboardHeader");
        // Get the offset position of the navbar
        var sticky = dashboardHeader?.offsetTop;
        // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
        if (window.pageYOffset > sticky) {
            dashboardHeader.classList?.add("the_default_value");
        } else {
            dashboardHeader.classList?.remove("the_default_value");
        }

    }


    componentDidMount() {
        this.init();
        window?.addEventListener("scroll", this.handleScroll);
        window?.addEventListener("the_default_value", this.stickyHeader);
    }
    init() {
        if (this.props.orders) {
            // this.setState({ ordersone: this.props.orders });
            this.setState({ ordersone: this.props.orders });
        }
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevState.requested !== false) {

        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    filterByStatus = (status) => {

        /* SETTING STATUS */
        if (!status.length) {
            this.setState({ searchByStatus: false, search: '' })

        } else {
            this.setState({ searchByStatus: true });
        }
    }

    // FORMAT DELIVERY DATE
    formatDeliveryDateToISOString = (deliveryDate) => {
        if (deliveryDate) {
            var date = new Date(this.search);
            var results = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
                .toISOString()
                .split("T")[0];
            return results
        }
    }
    render() {
        // const { paginationPage, search, setSearch, paginateFunction, paginatedOrders, setPaginatedOrders } = this.context;
        const children = React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement(child, {
                ordersHoc: this.props.allOrders//this.props.orders
            });
        });

        return (
            <div>
                {children}
            </div>
        )
    }
}


export default withRouter(DashboardHoc);