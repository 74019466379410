import React from 'react'
import { Route, Redirect } from 'react-router-dom';
import cookie from './cookie';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (cookie.get('token')) {
          return <Component {...props} />
        }
        return <Redirect to='/signin' />
      }}
    />
  )
}
export default ProtectedRoute;
