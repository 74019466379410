/* eslint-disable import/no-anonymous-default-export */
import cookie from "../utils/cookie";

export default config => {
  const access_token = cookie.get("token");
  const refresh_token = cookie.get("refresh_token");
  if (access_token && !config.url.includes('refresh')) {
    config.headers['Authorization'] = 'Bearer ' + access_token;
  }
  if(refresh_token) {
    config.headers['X-XSRF-TOKEN'] = 'Bearer ' + refresh_token;
  }
  if(config.method.toUpperCase() === "POST") {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
  }
  config.headers['Accept'] = 'application/json';
  config.headers['Cache'] = 'no-cache';
  return config;
};
