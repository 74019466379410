const updateQueryParams = (route, params = {}, customIdentifier = '') => {
  let identifier = ['?', '&'];
  if (customIdentifier && !identifier.includes(customIdentifier)) {
    identifier = identifier.concat(customIdentifier);
  }
  Object.keys(params).forEach(key => {
    const value = params[key];
    const re = new RegExp(`([${identifier.join('') }])` + key + '=.*?(&|$)', 'i')
    if (!(value === undefined || value === null)) {
      const separator = customIdentifier || (route.toString().indexOf('?') !== -1 ? '&' : '?');
      if (route.toString().match(re)) {
        route = route.toString().replace(re, '$1' + key + '=' + value + '$2');
      } else {
        route = route + separator + key + '=' + value;
      }
    } else {
      route = route.toString().replace(re, '?');
    }
  });
  return route;
};

export default updateQueryParams;
