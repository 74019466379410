import React, { useContext } from 'react';
import cookie from '../../utils/cookie';
import { useHistory } from "react-router";
import { OrderContext } from '../../context/OrderContext'
import NectarLogo from '../../assets/image/nectar-logo.png';
import LogoutIcon from '../../assets/icons/logout.svg';
import ShoppingBagIcon from '../../assets/icons/shopping-bag.svg';

function DashboardSideBar(props) {

    const {clearOrders } = useContext(OrderContext);
       
    let history = useHistory()
    // remove token and logout
    const logout = (e) => {
        e.preventDefault();
        clearOrders()
        localStorage.removeItem("location");
        localStorage.removeItem("username");
        localStorage.removeItem("password");
        localStorage.removeItem("checkbox");

        cookie.remove('refresh_token');
        cookie.remove('token');
        history.push("/")
    }

    return (
        <nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
            <div className="sidebar-sticky pt-3">
                <div className="text-center">
                    <img className="img-fluid mb-5" src={NectarLogo} alt="nectar logo" loading="lazy" width={90} height={90} />
                </div>
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <a className="nav-link active" href="/dashboard">
                            <span data-feather="home" />
                            <img className="" src={ShoppingBagIcon} alt="Shopping bag icon" />
                            <span className="nav-link-text" style={{ color: "#ffffff" }}>Orders</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={e => logout(e)} href="/#">
                            <span data-feather="file" />
                            <img className="" src={LogoutIcon} alt="Sign Out Icon" />
                            <span className="nav-link-text" style={{ color: "#ffffff" }}>Sign out</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    )
}

export default DashboardSideBar
