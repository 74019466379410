/* eslint-disable no-unused-vars */
import React, { useState, useEffect, createContext } from "react";
import moment from "moment";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import http from '../utils/http';
import updateQueryParams from '../utils/utility';
moment.suppressDeprecationWarnings = true;

export const OrderContext = createContext();

export const OrderProvider = props => {

  let history = useHistory();
  // if  this is true then we are genrating links so don'e set state
  const [initialLoad, setInitialLoad] = useState(true);

  const [paginationBtnLoading, setpaginationBtnLoading] = useState(false);
  // set location
  const [location, setLocation] = useState('');
  // loading state
  const [loading, setLoading] = useState(true);
  // Single Page
  const [singlePage, setSinglePage] = useState({})
  // Orders
  const [orders, setOrders] = useState([]);
  // Apended data
  const [appendData, setAppendData] = useState(false);
  // Pages with orders IDs
  const [ordersPages, setOrdersPages] = useState({});
  // Current Page
  const [currentPage, setCurrentPage] = useState(1);
  // Total Pages
  const [totalPages, setTotalPages] = useState(0);
  // Total Orders
  const [totalOrders, setTotalOrders] = useState(0)
  // Pending payment orders
  const [pendingOrders, setPendingOrders] = useState(0);
  // Processing payment orders
  const [processingOrders, setProcessingOrders] = useState(0);
  // Cancelled payment order
  const [cancelledOrders, setCancelledOrders] = useState(0);
  // On hold orders
  const [onholdOrders, setOnholdOrders] = useState(0);
  // Completed orders
  const [completedOrders, setCompletedPaymentOrders] = useState(0);
  // pagination page
  const [paginationPage, setPaginationPage] = useState(0);
  //pagination limit
  const [paginationLimit, setPaginationLimit] = useState(5);
  // filter type
  const [currentFilterType, setCurrentFilterType] = useState('');
  const [filterDateValue, setFilterDateValue] = useState('');
  // Length of currently paginated order
  const [paginatedOrdersLength, setPaginatedOrdersLength] = useState(0);

  /* IS ON LAST PAGE */
  const [isLastPage, setIsLastPage] = useState(false);

  /* LOADIM=G MORE REQUESTS */
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  /* PAGINATION FUNCTION */
  // Paginted Orders
  const [paginatedOrders, setPaginatedOrders] = useState([]);
  const [filterPaginationOrderLength, setFilterPaginationOrderLength] = useState(paginatedOrders.length);

  // status active nav
  const [activeNavItem, setActiveNavItem] = useState('all-status');

  // Display No more Orders
  const [noMoreOrders, setNoMoreOrders] = useState(false);

  let OrderForPagination = orders;
  const paginateFunction = (page = 1, limit = 10, options = {}, url = '', event) => {
    limit = parseInt(limit, 10);
    page = parseInt(page, 10);
    const startIndex = (page - 1) * limit;
    const numberOfPages = Math.ceil(OrderForPagination.length / limit);
    let ordersForPag = OrderForPagination;

    if (currentFilterType !== "") {
      ordersForPag = OrderForPagination.filter((order) => {
        let theDeliveryDate = moment(getDeliveryDate(order)).format("YYYY-MM-DD");
        let theSearchDate = moment(filterDateValue).format("YYYY-MM-DD");
        return theDeliveryDate.includes(theSearchDate);
      });
    }

    let result = {
      results: ordersForPag.slice(startIndex, limit + startIndex),
      page,
      limit,
      total: OrderForPagination.length,
      number_of_pages: numberOfPages,
    };

    if (page > 1) {
      result.previous_page = + page <= numberOfPages ? + page - 1 : numberOfPages;
    }
    if (page < numberOfPages) result.next_page = + page + 1;
    setPaginatedOrders(result.results)
    return result;
  };
  /* END OF PAGINATION FUNCTION */

  /* Retrieve cakePictures in localstorage  */
  if (localStorage.getItem("cakePictures") === null) {
    localStorage.setItem("cakePictures", JSON.stringify([]))
  }
  const cakePicturesString = localStorage.getItem("cakePictures");
  const cakePicturesArray = JSON.parse([cakePicturesString]);
  const url = process.env.REACT_APP_API_URL;
  const page = currentPage;
  // conditionally assign vaiable to either state or localstorage
  const userSelectedLocation = (location.length > 1) ? (location) : (localStorage.getItem('location'));

  /* CLEAR ORDERS */
  const clearOrders = () => {
    setOrders([])
  }

  // check if picture id exits
  const idExists = (id) => {
    return cakePicturesArray.some(function (el) {
      return el.id === id;
    });
  }

  /* Fetch picture url */
  const fetchProductImage = async (id) => {
    const idPresentInLocalStorage = cakePicturesArray.find((element) => {
      return element.id === id;
    });

    // check if image id already exist in cache before making another request
    // if it does retrieve the image from cache if not, make the request to get the image
    if (idPresentInLocalStorage) {
      const pictureLocalStorageURL = () => {
        var data = cakePicturesArray.filter(function (hero) {
          return hero.id === id;
        });
        return data[0].imageURL;
      }
      return pictureLocalStorageURL;

    } else {
      // make request
      // Check if id exists if it does return the image in that id
      // if not make request to get image
      return await http.get(`${process.env.REACT_APP_API_URL}/wc/v3/products/${id}`).then((response) => {
        const productImageURL = response.data.images[0]["src"]
        // retrieve cakePictures
        const cakePicturesString = localStorage.getItem("cakePictures");
        const cakePicturesArray = JSON.parse([cakePicturesString]);
        const picture = { "id": id, "imageURL": productImageURL };
        // Check if id exists before saving it
        if (!idExists(id)) {
          // Update cake pictures array
          cakePicturesArray.push(picture);
          // set cake pictures array with new value
          localStorage.setItem('cakePictures', JSON.stringify(cakePicturesArray));
        }
        return productImageURL;
      })
    }
  }

  // specify location parameter for filtering
  const filterLocation = (orders) => {
    // return orders.shipping.state === "OG" // process.env.REACT_APP_LOCATION;  // FOR WBCSTAGING VERSION
    return orders.filter(order => order.meta_data.some(item => item.value === userSelectedLocation));
  };

  // filter orders from specific location from total orders
  const filteredOrders = (orders) => {
    if (orders.length > 0) {
      const result = filterLocation(orders);
      return result;
    }
  };

  /* search query */
  const [searchByStatus, setSearchByStatus] = useState(false);

  const functionSearchByStatus = (search, order) => {
    if (searchByStatus) {
      return order.status === search
    } else if (searchByStatus === false && search === "all") {
      return order.status !== search;
    }
    else {
      return (
        order.id.toString().includes(search.toString())
      );
    }
  }

  // filter by delivery date
  const getDeliveryDate = (itemData) => {
    const data = itemData.meta_data.filter(item => item.key === 'Delivery Date');
    return data[0].value;
  }

  function searchFunctionality(search) {

    const re = /[0-9]{4}-[0-9]{2}-[0-9]{2}/

    if (!search.length) {
      setPaginatedOrders([...orders]);
      return paginatedOrders;
    }

    if (!re.test(search)) {
      const TheSearchResult = paginatedOrders.filter((order) => {
        return functionSearchByStatus(search, order);
      });
      setPaginatedOrders([...TheSearchResult]);
    }
    else {
      // setting
      setFilterDateValue(search);
      setCurrentFilterType('date');

      const DateFiltering = orders.filter((order) => {
        let theDeliveryDate = moment(getDeliveryDate(order)).format("YYYY-MM-DD")
        let theSearchDate = moment(search).format("YYYY-MM-DD")
        let newDeliveryDateData = theDeliveryDate.includes(theSearchDate)
        return newDeliveryDateData;
      });
      setPaginatedOrders([...DateFiltering]);
      setPaginatedOrdersLength(DateFiltering.length)

    }
  }

  /* Reset Date State */
  const resetDateState = () => {
    // setPaginatedOrders([...orders.slice(0, 10)])
    setPaginatedOrders([...orders])
    return orders
  }

  /* FILTER ORDER STATUS */
  const filterOrderStatus = (status) => {

    if (!status.length) {
      setPaginatedOrders([...orders]);
      return paginatedOrders;
    }

    if (status === "all") {
      resetDateState();
      setCurrentFilterType('')
      setFilterDateValue('')
      setPaginatedOrders([...orders])
      return orders
    }
    let newOrderStatus = Object.values(orders).filter(order => order.status === status);
    setPaginatedOrders([...newOrderStatus])
    return newOrderStatus;
  }
  /* END OF FILTER ORDER STATUS */

  const [completedModalBtnLoading, setCompletedModalBtnLoading] = useState(false);

  /* Update order status */
  const updateOrderStatus = (order) => {
    setCompletedModalBtnLoading(true)
    const index = orders.findIndex(x => x.id === order.id);
    http.put(`${process.env.REACT_APP_API_URL}/wc/v3/orders/${order.id}`, { status: 'completed' }).then((response) => {
      if (response.status === 200 && response.data) {
        if (index !== -1) {
          const res = orders.map(obj => response.data.id === obj.id ? response.data : obj);
          setOrders(res)
        }
        setCompletedModalBtnLoading(false)
        toast.info(`Status for #${order.id} updated to completed`);
      }
    }).catch(function (error) {
      setCompletedModalBtnLoading(false)
    });
  }

  /* Update order status from Single Product Page */
  const updateSingleOrderStatus = (orderStatus, order) => {
    const index = orders.findIndex(x => x.id === order.id);
    http.put(`${process.env.REACT_APP_API_URL}/wc/v3/orders/${order.id}`, { status: orderStatus }).then((response) => {
      if (response.status === 200 && response.data) {
        if (index !== -1) {
          const res = orders.map(obj => response.data.id === obj.id ? response.data : obj);
          setOrders(res)
        }
        toast.info(`Status for #${order.id} has been updated to ${orderStatus}`);
      }
    }).catch(function (error) {
    });
  }

  const updateRequest = (nextPage) => {
    setIsLoadingMore(true)
    let previousOrdersLength = orders;

    /* Load more orders */
    setAppendData(true);
    http.get(`${url}/wc/v3/orders?per_page=60&page=${nextPage}`).then((response) => {

      if (response.status === 200 && response.data) {
        // If we don't have the total orders already, get them from the headers sent by WP.
        // set total pages
        setTotalPages(parseInt(response.headers['x-wp-totalpages']));
        // Merge previously loaded orders with new ones.
        let _ordersPages = ordersPages;
        _ordersPages[page] = [];
        let filteredData = filteredOrders(response.data);

        // Nothing to display
        if (filteredData === previousOrdersLength && totalPages === nextPage - 1) {
          // we have gotten to the last page of the API
          setIsLastPage(true)
        }

        //  NOTHING TO DISPLAY 

        // Get last order in filtered data
        let lastI = filteredData[filteredData.length - 1];
        let lastDayCreated = lastI.date_created;

        // check if the last order is 5 months away from todays date
        function diff_months(dt2, dt1) {
          var diff = (dt2.getTime() - dt1.getTime()) / 1000;
          diff /= (60 * 60 * 24 * 7 * 4);
          return Math.abs(Math.round(diff));
        }

        let dt1 = new Date();
        let dt2 = new Date(lastDayCreated);
        let months_away = diff_months(dt1, dt2)
        //  END OF NOTHING TO DISPLAY 


        // CHECK IF NEXT PAGE EXISTS
        if (nextPage > 1 && nextPage < parseInt(response.headers['x-wp-totalpages'])) {
          // set loading to false  paginationBtnLoading
          setCurrentPage(nextPage);
          setpaginationBtnLoading(false)
        }

        // If only date and status is set  ( FILTER BY DATE )
        if ((currentFilterType !== "") && (activeNavItem === "pending" || activeNavItem === "processing" || activeNavItem === "cancelled" || activeNavItem === "on-hold" || activeNavItem === "completed")) {
          // SET TEXT TO NO MORE ORDERS
          if (months_away >= 5) {
            setNoMoreOrders(true)
          }

          const filterStatus = (orders, status) => {
            return Object.values(orders).filter(order => order.status === status);
          }

          let statusfiltering = filterStatus(filteredData, activeNavItem)
          const statusDateFiltering = statusfiltering.filter((order) => {
            let theDeliveryDate = moment(getDeliveryDate(order)).format("YYYY-MM-DD");
            let theSearchDate = moment(filterDateValue).format("YYYY-MM-DD");
            let newDeliveryDateData = theDeliveryDate.includes(theSearchDate);
            return newDeliveryDateData;
          });
          setOrdersPages(_ordersPages);
          setOrders([...orders, ...filteredData]);
          setPaginatedOrders([...paginatedOrders, ...statusDateFiltering]);
          setTotalOrders(totalOrders + filteredData.length);
          setFilterPaginationOrderLength(paginatedOrders.length);
          setPaginatedOrdersLength(paginatedOrdersLength + statusDateFiltering.length);
        }

        // if only status is set
        else if (activeNavItem === "pending" || activeNavItem === "processing" || activeNavItem === "cancelled" || activeNavItem === "on-hold" || activeNavItem === "completed") {
          // SET TEXT TO NO MORE ORDERS
          if (months_away >= 5) {
            setNoMoreOrders(true)
          }
          const filterStatus = (orders, status) => {
            return Object.values(orders).filter(order => order.status === status);
          }

          let statusfiltering = filterStatus(filteredData, activeNavItem)
          setOrdersPages(_ordersPages);
          setOrders([...orders, ...filteredData]);
          setPaginatedOrders([...paginatedOrders, ...statusfiltering]);
          setTotalOrders(totalOrders + filteredData.length);
          setFilterPaginationOrderLength(paginatedOrders.length);
          setPaginatedOrdersLength(paginatedOrdersLength + statusfiltering.length);
        }

        // if only date is set
        else if (currentFilterType !== "") {

          // SET TEXT TO NO MORE ORDERS
          if (months_away >= 5) {
            setNoMoreOrders(true)
          }

          // Filter delivery date that matches user date
          const DateFiltering = filteredData.filter((order) => {
            let theDeliveryDate = moment(getDeliveryDate(order)).format("YYYY-MM-DD");
            let theSearchDate = moment(filterDateValue).format("YYYY-MM-DD");
            let newDeliveryDateData = theDeliveryDate.includes(theSearchDate);
            return newDeliveryDateData;
          });
          setOrdersPages(_ordersPages);
          setOrders([...orders, ...filteredData]);
          setPaginatedOrders([...paginatedOrders, ...DateFiltering]);
          setTotalOrders(totalOrders + filteredData.length);
          setFilterPaginationOrderLength(paginatedOrders.length);
          setPaginatedOrdersLength(paginatedOrdersLength + DateFiltering.length);
        }

        // if none is set
        else {
          setOrdersPages(_ordersPages);
          setOrders([...orders, ...filteredData]);
          setPaginatedOrders([...paginatedOrders, ...filteredData]);
          setTotalOrders(totalOrders + filteredData.length);
        }
        setLoading(false);
        setpaginationBtnLoading(false);
        setIsLoadingMore(false)

      }
    }).catch(function (error) {
      setpaginationBtnLoading(false);
      setIsLoadingMore(false)
    });
  }


  const initialRequest = () => {
    http.get(updateQueryParams(`${url}/wc/v3/orders?per_page=60`, { page: currentPage }))
      .then(function (response) {
        if (response.status === 200 && response.data) {
          // If we don't have the total orders already, get them from the headers sent by WP.
          if (totalPages === 0 && Object.keys(response.headers).indexOf('x-wp-totalpages') >= 0) {
            setTotalPages(parseInt(response.headers['x-wp-totalpages']));
          }
          // set total pages
          setTotalPages(parseInt(response.headers['x-wp-totalpages']));
          // Merge previously loaded orders with new ones.
          let _ordersPages = ordersPages;
          _ordersPages[page] = [];
          let filteredData = filteredOrders(response.data)
          setOrdersPages(_ordersPages);
          setOrders([...orders, ...filteredData]);
          setPaginatedOrders([...paginatedOrders, ...filteredData]);
          setTotalOrders(filteredData.length)
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.error(error);
      });
  }


  // Using useEffect to load new orders on change.
  useEffect(() => {
    if (appendData === false) {
      // set the current page
      const page = currentPage;
      // If we have orders for that page already, don't load.
      if (Object.keys(ordersPages).indexOf(page.toString()) >= 0) {
        return;
      }
      setLoading(true);
    }
  }, [userSelectedLocation, location, history, orders, loading, currentPage, ordersPages, page, totalPages, url, appendData]);

  return (
    <OrderContext.Provider
      value={{
        orders: orders,
        updateRequest: updateRequest,
        currentPage: currentPage,
        setOrders: setOrders,
        setCurrentPage: setCurrentPage,
        loading: loading,
        setLoading: setLoading,
        ordersPages: ordersPages,
        totalPages: totalPages,
        singlePage: singlePage,
        setSinglePage: setSinglePage,
        setOrdersPages: setOrdersPages,
        totalOrders: totalOrders,
        setTotalOrders: setTotalOrders,
        pendingOrders: pendingOrders,
        setPendingOrders: setPendingOrders,
        processingOrders: processingOrders,
        setProcessingOrders: setProcessingOrders,
        cancelledOrders: cancelledOrders,
        setCancelledOrders: setCancelledOrders,
        onholdOrders: onholdOrders,
        setOnholdOrders: setOnholdOrders,
        completedOrders: completedOrders,
        setCompletedPaymentOrders: setCompletedPaymentOrders,
        location: location,
        setLocation: setLocation,
        initialRequest: initialRequest,
        paginationBtnLoading: paginationBtnLoading,
        setpaginationBtnLoading: setpaginationBtnLoading,
        clearOrders: clearOrders,
        updateOrderStatus: updateOrderStatus,
        completedModalBtnLoading: completedModalBtnLoading,
        setCompletedModalBtnLoading: setCompletedModalBtnLoading,
        fetchProductImage: fetchProductImage,
        paginationPage: paginationPage,
        setPaginationPage: setPaginationPage,
        paginationLimit: paginationLimit,
        setPaginationLimit: setPaginationLimit,
        filterOrderStatus: filterOrderStatus,
        initialLoad: initialLoad,
        paginateFunction: paginateFunction,
        paginatedOrders: paginatedOrders,
        setPaginatedOrders: setPaginatedOrders,
        searchFunctionality: searchFunctionality,
        updateSingleOrderStatus: updateSingleOrderStatus,
        resetDateState: resetDateState,

        // filter by date
        currentFilterType: currentFilterType,
        setCurrentFilterType: setCurrentFilterType,
        filterDateValue: filterDateValue,
        setFilterDateValue: setFilterDateValue,
        filterPaginationOrderLength: filterPaginationOrderLength,

        paginatedOrdersLength: paginatedOrdersLength,
        setPaginatedOrdersLength: setPaginatedOrdersLength,

        isLastPage: isLastPage, setIsLastPage: setIsLastPage,
        isLoadingMore: isLoadingMore,

        activeNavItem: activeNavItem, setActiveNavItem: setActiveNavItem, getDeliveryDate: getDeliveryDate, moment: moment,
        noMoreOrders: noMoreOrders
      }}>
      {props.children}
    </OrderContext.Provider>
  )
}

export default OrderProvider;
