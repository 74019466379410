/* eslint-disable no-unused-vars */
import React, { useContext, useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ScrollToTop from "react-scroll-to-top";
import DashboardHoc from './DashboardHoc';
import Navigator from '../../components/Navigator/Navigator';
import { OrderContext } from '../../context/OrderContext';
import OrdersPagination from '../../components/OrdersPagination/OrdersPagination';
import DashboardSideBar from '../../components/DashboardSideBar/DashboardSideBar';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';

import './Dashboard.css';
import "antd/dist/antd.css";

const Dashboard = ({ orders, paginatedOrders }) => {

  const navigationalList = useContext(OrderContext);
  const {
    location, setPendingOrders,
    setProcessingOrders, setCancelledOrders,
    setOnholdOrders, setCompletedPaymentOrders, paginationBtnLoading,
    searchFunctionality, filterOrderStatus, resetDateState, setCurrentFilterType, setFilterDateValue, filterDateValue,
    paginatedOrdersLength, currentFilterType, isLastPage, activeNavItem, setActiveNavItem, getDeliveryDate, moment, noMoreOrders
  } = navigationalList;

  let userCurrentLocation = (location.length > 1) ? (location) : (localStorage.getItem('location'));


  const [navItems, setNavItems] = useState([
    {
      "id": "all",
      "statusType": "All",
      "statusState": 'totalOrders',
    },
    {
      "id": "pending",
      "statusType": "Pending",
      "statusState": 'pendingOrders'
    },
    {
      "id": "processing",
      "statusType": "Processing",
      "statusState": 'processingOrders'
    },
    {
      "id": "cancelled",
      "statusType": "Cancelled",
      "statusState": 'cancelledOrders',
    },
    {
      "id": "on-hold",
      "statusType": "On-hold",
      "statusState": 'onholdOrders'
    },
    {
      "id": "completed",
      "statusType": "Completed",
      "statusState": 'completedOrders'
    },
  ])



  const filterStatus = (orders, status) => {
    return Object.values(orders)?.filter(order => order.status === status).length;
  }

  // Filter orders by status type
  const filterByStatus = (event, status) => {
    setActiveNavItem(status)

    /* SETTING STATUS */
    if (status.length < 1) {

    }
    let searchResult = filterOrderStatus(status)
    // add if here 

    if (currentFilterType !== "") {

      const DateFiltering = searchResult.filter((order) => {
        let theDeliveryDate = moment(getDeliveryDate(order)).format("YYYY-MM-DD");
        let theSearchDate = moment(filterDateValue).format("YYYY-MM-DD");
        let newDeliveryDateData = theDeliveryDate.includes(theSearchDate);
        return newDeliveryDateData;
      });



      paginatedOrders = DateFiltering;

    } else {
      paginatedOrders = searchResult;
    }

  }


  /* SEARCH BY STATUS */
  /* status */
  const totalPendingOrders = filterStatus(orders, 'pending');
  setPendingOrders(totalPendingOrders);

  const totalProcessingOrders = filterStatus(orders, 'processing');
  setProcessingOrders(totalProcessingOrders);

  const totalCancelledOrders = filterStatus(orders, 'cancelled');
  setCancelledOrders(totalCancelledOrders);

  const totalOnholdOrders = filterStatus(orders, 'on-hold');
  setOnholdOrders(totalOnholdOrders);

  const totalCompletedOrders = filterStatus(orders, 'completed');
  setCompletedPaymentOrders(totalCompletedOrders);
  /* end of status */

  const orderNumberSearch = (orderNumber) => {
    if (orderNumber.length < 1) {
    }
    let searchResult = searchFunctionality(orderNumber)
    paginatedOrders = searchResult
  }

  // date picker
  const [startDate, setStartDate] = useState(new Date());
  /* END OF PAGINATION */

  return (
    <div className="container-fluid">
      <div className="rows">
        <DashboardSideBar />

        <main role="main" className="col-md-9 ml-sm-auto col-lg-10">

          <DashboardHoc allOrders={paginatedOrders} orders={paginatedOrders}>
            <div id="dashboardHeader">
              <div className="container-fluid">

                <div className="row mt-3">
                  <div className="col-lg-6 col-sm-12">
                    <h1 className="orders-text" style={{ fontWeight: '400' }}>Orders in <strong>{userCurrentLocation}</strong></h1>
                  </div>

                  <div className="col-lg-6 col-sm-12 ">
                    <label className="search-label mt-1">
                      <input id="searchOrderNumber" name="searchOrderNumber"
                        className="form-control search-order-number"
                        onKeyUp={(e) => orderNumberSearch(e.target.value)}
                        placeholder="Search by Order number" />
                    </label></div>
                </div>

                <div className="row">
                  <div className="col-sm-12 details-text">
                    <Nav as="ul">

                      {
                        navItems.map((item) => (
                          <Nav.Item as="li" key={item.id}>
                            <Nav.Link className={`status-item ${activeNavItem === item.id ? "active-list" : ""}`} eventKey="#" onClick={(event) => filterByStatus(event, item.id)} >
                              {item.statusType} ({navigationalList[item.statusState]})</Nav.Link>
                          </Nav.Item>)
                        )
                      }
                      {/* <Nav.Item as="li">
                        <Nav.Link className="status-item" id="all-status" eventKey="#" onClick={(event) => { filterByStatus(event, 'all') }}
                         >All ({totalOrders})</Nav.Link>
                      </Nav.Item> */}

                      {/* <Nav.Item as="li">
                        <Nav.Link className="status-item" id="all-status" eventKey="#" onClick={(event) => { filterByStatus(event, 'all') }}
                         >All ({totalOrders})</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link className="status-item" id="pending-status" eventKey="#" onClick={(event) => { filterByStatus(event, 'pending') }}>
                          Pending payment ({pendingOrders})</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link className="status-item" id="processing-status" eventKey="#" onClick={(event) => { filterByStatus(event, 'processing') }}>
                          Processing ({processingOrders})</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link className="status-item" id="cancelled-status" eventKey="#" onClick={(event) => { filterByStatus(event, 'cancelled') }}>
                          Cancelled ({cancelledOrders})</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link className="status-item" id="on-hold-status" eventKey="#" onClick={(event) => { filterByStatus(event, 'on-hold') }}>
                          Onhold ({onholdOrders})</Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li">
                        <Nav.Link className="status-item" id="pending-status" eventKey="#" onClick={(event) => { filterByStatus(event, 'completed') }}>
                          Completed ({completedOrders})</Nav.Link>
                      </Nav.Item> */}


                    </Nav>
                  </div>
                </div>

                <Navigator
                  orders={orders} startDate={startDate}
                  setStartDate={setStartDate}
                  searchFunctionality={searchFunctionality}
                  resetDateState={resetDateState}
                  setCurrentFilterType={setCurrentFilterType}
                  setFilterDateValue={setFilterDateValue}
                />
              </div>

              <div className="d-none tabcols ">
                <div className="d-none d-lg-block table-rows w-100">
                  <div className="row m-0">
                    <div className="col">Order#</div>
                    <div className="col">Customer Name</div>
                    <div className="col">Created Date</div>
                    <div className="col">Status</div>
                    <div className="col">Billing</div>
                    <div className="col">Ship to</div>
                    <div className="col">Total</div>
                    <div className="col" id="th-delivery-date">Delivery Date</div>
                  </div>
                </div>
                <div className="d-block d-lg-none table-rows w-100">
                  <div className="row m-0">
                    <div className="col">Order#</div>
                    <div className="col">Customer Name</div>
                    <div className="col">Created Date</div>
                    <div className="col">Total</div>
                    <div className="col" id="th-delivery-date">Delivery Date</div>
                  </div>
                </div>
              </div>
            </div>

            <OrdersPagination myorders={orders} />

            <Row>
              <Col className="mt-2">
                <div className="float-right">
                  {/* Nested ternary */}
                  {(noMoreOrders) ? (<span> No more orders</span>) : ((orders.length > 0) ? ((paginationBtnLoading ? (<LoadingSpinner />) : (<div></div>))) : (<div></div>))}
                  {/* {(orders.length > 0) ? ((paginationBtnLoading ? (<LoadingSpinner />) : (<div></div>))) : (<div></div>)} */}
                </div>
              </Col>

              <Col>
                <div className="float-right">
                  {(paginatedOrders.length) ? (<span>{paginatedOrders.length} Items</span>) : (<div></div>)}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="mt-2">
                <div className="float-right">
                  {/* {(isLastPage) ? (<span> No more orders</span>) : (<div></div>)} */}
                </div>
              </Col>
              <Col></Col>
            </Row>
          </DashboardHoc>
        </main>
      </div>
      <ScrollToTop color="#fff" style={{ background: "#3B007D" }} smooth />
    </div>
  );
}

export default Dashboard;