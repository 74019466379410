import React, { Fragment, useContext, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";

import { OrderContext } from '../../context/OrderContext';
import http from '../../utils/http';
import cookie from '../../utils/cookie';

import NectarLogo from "../../assets/image/nectar-logo.png";
import Background from "../../assets/image/bakground-image.png";

import EyeFilled from "../../assets/icons/eye.svg";
import EyeCancelled from "../../assets/icons/eye-cancelled.svg";

const SignIn = ({ history }) => {
  const { setLocation } = useContext(OrderContext);
  const [isLoading, setLoading] = useState(false);

  const [error, setError] = useState("");
  const [authInfo, setAuthInfo] = useState({
    username: "",
    password: ""
  });

  // Password visibility
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };


  // User selected location
  let selectedLocation = document.getElementById('inputLocation');

  /* Remeber me */
  const [isChecked, setIsChecked] = useState(false);



  useEffect(() => {
    if (localStorage.getItem("checkbox") && (localStorage.getItem("username") !== null && localStorage.getItem("password") !== null)) {
      document.getElementById("username").value = localStorage.getItem("username");
      document.getElementById("password").value = localStorage.getItem("password")
      setIsChecked(true)
    }
  }, []);

  // change checkbox     
  const onChangeCheckbox = event => {
    setIsChecked(event.target.checked)
  }

  /* END OF Remember me */


  const handleChange = (e) => {
    setAuthInfo({
      ...authInfo,
      [e.target.name]: e.target.value,
    });
  };

  const selectChange = (e) => {
    // set location to state
    setLocation(selectedLocation.options[selectedLocation.selectedIndex].text)
    // set location to localstorage
    localStorage.setItem("location", selectedLocation.options[selectedLocation.selectedIndex].text);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (isChecked && authInfo.username !== "") {
      localStorage.setItem("username", authInfo.username)
      localStorage.setItem("password", authInfo.password)
      localStorage.setItem("checkbox", isChecked)
    }

    const validate = (emailAddress, location) => {
      if (emailAddress.includes(location)) {
        return true
      }
      setError('You are not authorized to view this location');
      setLoading(false);
      return false;

    }

    // check if validate evaluates to true
    if (validate(authInfo.username, selectedLocation.options[selectedLocation.selectedIndex].value)) {
      http.post(`${process.env.REACT_APP_API_URL}/jwt-auth/v1/token`, authInfo)
        .then((response) => {
          setLoading(false);
          cookie.put("token", response.data.token);
          cookie.put("refresh_token", response.data.refresh_token);
          history.push("/dashboard");
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            history.push("/error");
          } else {
            setError(error.response.data.message);
            setLoading(false);
          }
        });
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${Background})`,
    objectFit: "fill",
    width: "100%",
  };

  return (
    <Fragment>
      <img
        src={Background}
        style={backgroundStyle}
        loading="lazy"
        alt="Nectar banner background"
      />
      <div className="container text-center center-form">
        <form className="col-sm-12 form-signin" onSubmit={handleSubmit}>
          {error && (
            <Alert variant="danger">
              <strong
                dangerouslySetInnerHTML={{ __html: error || "" }}
              ></strong>
            </Alert>
          )}
          <img
            className="mb-5"
            src={NectarLogo}
            alt="nectar logo"
            loading="lazy"
            width="72"
            height="72"
          />

          <div className="mb-3">
            <label htmlFor="username" className="sr-only">
              Email address
            </label>
            <input
              type="email"
              id="username"
              name="username"
              value={authInfo.username}
              onChange={handleChange}
              onKeyUp={handleChange}
              autoComplete="on"
              className="form-control"
              placeholder="Email address"
              required
              autoFocus
            />
          </div>

          <div className="mb-3">
            <label htmlFor="inputPassword" className="sr-only">
              Password
            </label>

            <div className="pass-wrapper">
              <input
                type={passwordShown ? "text" : "password"}
                name="password"
                id="password"
                value={authInfo.password}
                className="form-control"
                onChange={handleChange}
                onKeyUp={handleChange}
                autoComplete="on"
                placeholder="Password"
                autoFocus
                required
              />
              <i
                onClick={togglePasswordVisiblity}>{(passwordShown) ? (<img src={EyeCancelled} alt="eye cancelled" />) : (<img src={EyeFilled} alt="eye filled" />)}</i>
            </div>
          </div>

          {/* SELECT TAG */}
          <div className="mb-4">
            <label htmlFor="inputLocation" className="sr-only">
              Location
            </label>
            <select
              name="inputLocation"
              id="inputLocation"
              onChange={selectChange}
              className="form-control form-control-signin"
              autoFocus
              required
            >
              <option value=""> Select a location</option>
              <option value="island">Lagos Island</option>
              <option value="mainland">Lagos Mainland</option>
              <option value="ikorodu">Ikorodu</option>
            </select>
          </div>

          <div className="checkbox mb-4 mt-2 float-left">
            <label>
              <input className="mr-2" type="checkbox" checked={isChecked} name="rememberMe" onChange={onChangeCheckbox} />
              <span>Remember me</span>
            </label>
          </div>

          <button
            className="btn btn-lg btn-dark btn-block btn-signin"
            type="submit"
          >
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
                "Sign in"
              )}
          </button>
        </form>
      </div>
    </Fragment>
  );
};

export default SignIn;
