/* eslint-disable no-unused-vars */
import React, { useState, useContext, useRef } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import updateQueryParams from '../../utils/utility';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

import { OrderContext } from '../../context/OrderContext';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import Eye from '../../assets/icons/eye.svg';
import './OrdersPagination.css';

const OrdersPagination = ({ myorders, ordersHoc }) => {
  const { loading, updateOrderStatus, setSinglePage, completedModalBtnLoading, setCompletedModalBtnLoading } = useContext(OrderContext);

  const formatDate = (date) => {
    const formattedDate = new Date(date).toDateString('dd-mm-yy')
    const [, month, day, year] = formattedDate.split(' ')
    return `${month} ${day}, ${year}`
  }

  const ref = useRef(null);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Quick View
    </Tooltip>
  );

  const completeOrder = (order) => {
    setCompletedModalBtnLoading(true)
    if (window.confirm(`Are you sure you want to update status of order ${order.id} to completed?`)) {
      updateOrderStatus(order)
    } else {
      setCompletedModalBtnLoading(false)
    }
  }

  const [show, setShow] = useState(false);
  let [modalContent, setModalContent] = useState();
  let location = useLocation();
  // let history = useHistory();
  // const getLink = nextPage => updateQueryParams(`${location.pathname}${location.search}?per_page=10`, {
  //   nextPage
  // });

  /*  const loadMore = () => {
      setpaginationBtnLoading(true)
      let nextPage = currentPage + 1;
      history.push(getLink(nextPage));
      updateRequest(nextPage)
      // setpaginationBtnLoading(false)
    }
    */


  // display modal pop up
  const displayModal = (item) => {
    setModalContent({ item })
    setShow(true)
  }
  const theSinglePage = (item) => {
    setSinglePage(item)
  }
  const getDeliveryDate = (itemData) => {
    const data = itemData.meta_data.filter(item => item.key === 'Delivery Date');
    return data[0].value;
  }
  /**
   * Show more information to make the table nicer
   * @param {*} e 
   */
  const toggleMore = (e) => {
    const dataset = e.target.dataset;

    if (dataset.src === 'more') {
      document.querySelector(`.more-${dataset.index}`).classList.add('d-none');
      document.querySelector(`.moreDetails-${dataset.index}`).classList.remove('d-none');
    } else if (dataset.src === 'less') {
      document.querySelector(`.more-${dataset.index}`).classList.remove('d-none');
      document.querySelector(`.moreDetails-${dataset.index}`).classList.add('d-none');
    }
  }

  const orders_data = ordersHoc.map((item, index) => {
    return (
      <tr key={item.id}>
        <td className="table-id">
          <Link className="customer-name" onClick={() => { theSinglePage(item) }} to="/single-order" >#{item.id}</Link>
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={renderTooltip}
            ref={ref}
          >
            <a href="#/" style={{ marginLeft: "0.5em" }} onClick={() => { displayModal(item) }}><img src={Eye} alt="nectar modal" width="15" height="15" /></a>
          </OverlayTrigger>
        </td>
        <td>
          <Link className="customer-name" onClick={() => { theSinglePage(item) }} to="/single-order" style={{ color: "#2E2E2E" }}>
            {item.billing.first_name} {item.billing.last_name}
          </Link>
        </td>
        <td>{formatDate(item.date_created)}</td>
        <td className={`${item.status} hide-col`}><span>{item.status}</span></td>
        <td className="hide-col">
          {item.billing.first_name} {item.billing.last_name}
          <br />
          {item.billing.address_1}
          <div className="d-none">
            <span className="text-muted">via {item.payment_method_title}</span>
          </div>

        </td>
        <td className="hide-col">
          {item.shipping.first_name} {item.shipping.last_name}
          <br />
          {item.shipping.address_1}
          <br />
          <p className={`show-more more-${index}`} data-src="more" data-index={index} onClick={toggleMore}>show more</p>
          <div className={`d-none moreDetails-${index}`}>
            <span className="text-muted">via {item.shipping_lines[0].method_title}</span>
            <p className={`show-more less-${index}`} data-src="less" data-index={index} onClick={toggleMore}>show less</p>
          </div>
        </td>
        <td>{new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(item.total)}</td>
        <td id="th-delivery-date">{getDeliveryDate(item)}</td>
      </tr>
    )
  });

  return (
    <Table responsive="md" className="table table-striped table-md">
      <thead id="orders-table-header">
        <tr className="table-row ">
          <th>Order#</th>
          <th>Customer Name</th>
          <th>Created Date</th>
          <th className="hide-col">Status</th>
          <th className="hide-col">Billing</th>
          <th className="hide-col">Ship to</th>
          <th>Total</th>
          <th id="th-delivery-date">Delivery Date</th>
        </tr>
      </thead>
      <tbody>
        {loading ? <tr style={{ textAlign: "center" }}><td colSpan="8"><LoadingSpinner /></td></tr> : (orders_data.length ? orders_data : <tr style={{ textAlign: "center" }}><td colSpan="8">No Data found</td></tr>)}
        {/* <div id="toScrollHere"></div> */}
        <Modal
          size="lg"
          centered
          animation={false}
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="custom-modal">
          {
            (modalContent === undefined) ? 'No data' : (
              <div>
                <Modal.Header closeButton>
                  <Modal.Title id="custom-modal">
                    Order #{modalContent.item.id}
                  </Modal.Title>
                  {/* {myorders.filter(obj => obj.id = modalContent.item.id)[0].status} */}
                  <span className="float-right modal-status">{modalContent.item.status}</span>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>

                      <Col style={{ paddingRight: "0" }}>
                        <ul className="modal-list">
                          <li className="modal-list-item">
                            <h5 className="modal-h5">Customer Details</h5>
                            <p className="modal-text">{modalContent.item.billing.first_name} {modalContent.item.billing.last_name}</p>
                            <p className="modal-text">{modalContent.item.billing.address_1}</p>
                          </li>
                          <li className="modal-list-item">
                            <h6>Email</h6>
                            <p className="modal-text"><a href={`mailto:${modalContent.item.billing.email}`} className="anchor-details">{modalContent.item.billing.email}</a></p>
                          </li>
                          <li className="modal-list-item">
                            <h6>Phone</h6>
                            <p className="modal-text"><a href={`tel:${modalContent.item.billing.phone}`} className="anchor-details">{modalContent.item.billing.phone}</a></p>
                          </li>
                          <li className="modal-list-item">
                            <h6>Payment via</h6>
                            <p className="modal-text">{modalContent.item.payment_method_title}</p>
                          </li>
                          <li className="modal-list-item">
                            <h6>Delivery Date</h6>
                            <p className="modal-text">{modalContent.item.meta_data[5].value}</p>
                          </li>
                        </ul>
                      </Col>

                      <Col>
                        <ul className="modal-list">
                          <li>
                            <h5 className="modal-h5">Shipping Details</h5>
                            <p><a href="/#" className="anchor-details">{modalContent.item.shipping.address_1}</a></p>
                          </li>
                          <li></li>
                          <li>
                            <h6>Shipping methods</h6>
                            <p>via {modalContent.item.shipping_lines[0].method_title}</p>
                          </li>
                        </ul>
                      </Col>

                      <Table striped borderless hover size="sm" style={{ display: "table" }}>
                        <thead>
                          <tr>
                            <th className="modal-table-head">Product</th>
                            <th className="modal-table-head">Quantity</th>
                            <th className="modal-table-head">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalContent.item.line_items.map((index, i) => (
                            <tr key={i}>
                              <td className="line-items"><a className="modal-single-order" href="/">{index.name}</a></td>
                              <td className="line-items">x{index.quantity}</td>
                              <td className="line-items">
                                {new Intl.NumberFormat('en-NG', { style: 'currency', currency: 'NGN' }).format(index.total)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Container>
                    <Row>
                      <Col sm={6}><button className="complete-order-btn" variant="secondary" onClick={() => { completeOrder(modalContent.item) }}>
                        {(completedModalBtnLoading ? (<LoadingSpinner />) : ('Click to complete order'))}
                      </button></Col>
                      <Col><Link className="edit-btn" variant="secondary" onClick={() => { theSinglePage(modalContent.item) }} to="/single-order">Edit</Link></Col>
                    </Row>
                  </Container>
                </Modal.Footer>
              </div>
            )}
        </Modal>
      </tbody>
    </Table>
  )
}

export default OrdersPagination
